import React from "react";
import PropTypes from "prop-types";

const ListIcon = ({ fill = "#575D64", height = 18, width = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_1986_1113"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1986_1113)">
        <path
          d="M5.25 6.75V5.25H15.75V6.75H5.25ZM5.25 9.75V8.25H15.75V9.75H5.25ZM5.25 12.75V11.25H15.75V12.75H5.25ZM3 6.75C2.7875 6.75 2.60938 6.67813 2.46562 6.53437C2.32187 6.39062 2.25 6.2125 2.25 6C2.25 5.7875 2.32187 5.60938 2.46562 5.46562C2.60938 5.32187 2.7875 5.25 3 5.25C3.2125 5.25 3.39062 5.32187 3.53437 5.46562C3.67813 5.60938 3.75 5.7875 3.75 6C3.75 6.2125 3.67813 6.39062 3.53437 6.53437C3.39062 6.67813 3.2125 6.75 3 6.75ZM3 9.75C2.7875 9.75 2.60938 9.67812 2.46562 9.53438C2.32187 9.39062 2.25 9.2125 2.25 9C2.25 8.7875 2.32187 8.60938 2.46562 8.46562C2.60938 8.32187 2.7875 8.25 3 8.25C3.2125 8.25 3.39062 8.32187 3.53437 8.46562C3.67813 8.60938 3.75 8.7875 3.75 9C3.75 9.2125 3.67813 9.39062 3.53437 9.53438C3.39062 9.67812 3.2125 9.75 3 9.75ZM3 12.75C2.7875 12.75 2.60938 12.6781 2.46562 12.5344C2.32187 12.3906 2.25 12.2125 2.25 12C2.25 11.7875 2.32187 11.6094 2.46562 11.4656C2.60938 11.3219 2.7875 11.25 3 11.25C3.2125 11.25 3.39062 11.3219 3.53437 11.4656C3.67813 11.6094 3.75 11.7875 3.75 12C3.75 12.2125 3.67813 12.3906 3.53437 12.5344C3.39062 12.6781 3.2125 12.75 3 12.75Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

ListIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default ListIcon;
