import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AppHeader from "../../../../../components/AppHeader/AppHeader";
import GeocodingSearch from "../../../../../components/GeocodingSearch/GeocodingSearch";
import ListIcon from "../../../../../components/Icons/ListIcon";
import MapMarkerIcon from "../../../../../components/Icons/MapMarkerIcon";
import StickyHeader from "../../../../../components/StickyHeader/StickyHeader";
import { useFeatureFlags } from "../../../../../contexts/FeatureFlagContext";
import ListingFilter from "../ListingFilter/ListingFilter";

const Header = ({
  translationUrls,
  lang,
  t,
  user,
  regionSlug,
  capitalizedRegionSlug,
  onChangeRegion,
  isSmallScreen,
  query,
  onChangeFilter,
  isMapView,
  switchToMapView,
  switchToListView,
  updateURL,
  logoProps,
  currentUrl,
  analyticsEvent,
  searchBias,
  url,
  regionBbox,
}) => {
  const region = {
    label: capitalizedRegionSlug,
    value: regionSlug,
  };

  const { isFeatureFlagEnabled } = useFeatureFlags();
  return (
    <Fragment>
      <AppHeader
        lang={lang}
        t={t}
        user={user}
        logoProps={logoProps}
        currentUrl={currentUrl}
        translationUrls={translationUrls}
        theme="white"
        border={false}
        analyticsEvent={analyticsEvent}
      >
        <GeocodingSearch
          className="HeaderInputSearch"
          lang={lang}
          bias={searchBias}
          value={region.label}
          onSelectSuggestion={onChangeRegion}
          placeholder={t("components.GeocodingInputSearch.placeholder")}
        />
      </AppHeader>

      <div className="Listings-bar">
        <ListingFilter
          t={t}
          isSmallScreen={isSmallScreen}
          onChange={onChangeFilter}
          updateURL={updateURL}
          regionSlug={regionSlug}
          query={query}
          lang={lang}
          analyticsEvent={analyticsEvent}
          url={url}
          currentUrl={currentUrl}
          regionBbox={regionBbox}
          isFeatureFlagEnabled={isFeatureFlagEnabled}
        />

        {!isSmallScreen && (
          <div className="Listings-viewSelector">
            <button
              className={classNames(
                "Listings-viewListButton",
                !isMapView && "Listings-viewListButton--active",
              )}
              onClick={switchToListView}
            >
              <ListIcon fill={!isMapView ? "#fff" : "#575d64"} />
              <span>{t("list")}</span>
            </button>
            <button
              className={classNames(
                "Listings-viewMapButton",
                isMapView && "Listings-viewMapButton--active",
              )}
              onClick={switchToMapView}
            >
              <MapMarkerIcon fill={isMapView ? "#fff" : "#575d64"} />
              <span>{t("map")}</span>
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

Header.propTypes = {
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  user: PropTypes.object,
  regionSlug: PropTypes.string.isRequired,
  onChangeRegion: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func,
  isSmallScreen: PropTypes.bool,
  query: PropTypes.object,
  isMapView: PropTypes.bool.isRequired,
  switchToMapView: PropTypes.func.isRequired,
  switchToListView: PropTypes.func.isRequired,
  updateURL: PropTypes.func.isRequired,
  logoProps: PropTypes.object,
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func,
  translationUrls: PropTypes.object,
  searchBias: PropTypes.string,
  capitalizedRegionSlug: PropTypes.string,
  url: PropTypes.func.isRequired,
  regionBbox: PropTypes.string,
};

/* eslint-disable new-cap */
export default StickyHeader(Header);
